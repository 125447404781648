<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <base-card>
          <div slot="heading" class="title font-weight-light">
            {{ $t('bpo.create_new_title') }}
          </div>
          <v-form ref="form" @submit.prevent="onAddClicked">
            <v-text-field
              v-model.trim="name"
              :label="$t('bpo.bpo_name')"
              :rules="nameRules"
              required
            />
            <v-card-actions>
              <v-spacer />
              <v-btn dark color="teal" type="submit" :loading="isCreating">
                {{ $t('common.create') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    BaseCard,
  },
  data() {
    return {
      name: '',
      nameRules: [(v) => !!v || 'Name is required'],
      isCreating: false,
    }
  },
  methods: {
    ...mapActions(['createBPO']),
    onAddClicked() {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      const payload = {
        name: this.name,
      }
      this.isCreating = true
      this.createBPO(payload)
        .then(() => {
          this.isCreating = false
          this.$router.push('/')
          this.$snotify.success(this.$t('common.create_successfully'))
        })
        .catch(() => {
          this.isCreating = false
        })
    },
  },
}
</script>

<style>
.card {
  padding: 32px;
}
</style>
